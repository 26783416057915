<script setup lang="ts">
import { ExclamationCircleIcon } from '@heroicons/vue/outline';
const { $t } = useNuxtApp();

const props = defineProps<{
  class?: string;
  tooltip: string;
}>();
</script>

<template>
  <div :title="$t(props.tooltip)" :class="props.class" class="cursor-help">
    <ExclamationCircleIcon class="" />
  </div>
</template>

<style>
/* div[title]:hover::after {
  background-color: #fff !important;
} */
</style>
