<script setup lang="ts">
import Password from 'primevue/password';
import type { InputProps } from '~/interfaces/form/textInputInterface';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/outline/index.js';
import ExclamationIcon from '../icons/ExclamationIcon.vue';
import CircleIcon from '../icons/CircleIcon.vue';
const { $t } = useNuxtApp();

const props = withDefaults(defineProps<InputProps>(), {
  class: '',
  formKey: '',
  label: '',
  modelValue: '',
  feedback: true,
  labelClass: '',
});

const modelValue: globalThis.Ref<string | number> = props.modelValue
  ? ref(props.modelValue)
  : ref('');
const emit = defineEmits(['update:modelValue', 'blur']);

function showValue(model: any) {
  const passwordChecker = document.getElementsByClassName(
    'p-hidden-accessible'
  )[1];
  const passwordCheckerValue = passwordChecker.innerHTML;

  const parentElement = passwordChecker.parentElement;

  if (passwordCheckerValue.trim() == 'Strong') {
    parentElement?.setAttribute(
      'class',
      'p-password p-component p-inputwrapper p-inputwrapper-filled p-input-icon-right'
    );

    const passwordField = document.getElementById('password');
    passwordField?.setAttribute('aria-expanded', 'false');
  }
}

const input: globalThis.Ref<Password> = ref();
const showChevron = ref(false);
const successGreen = 'success-500';

const passwordSuggestions = reactive([
  {
    text: $t('at-least-one-lower'),
    display: false,
    regex: ['([a-z])'],
    icon: CheckCircleIcon,
    colour: successGreen,
  },
  {
    text: $t('at-least-one-upper'),
    display: false,
    regex: ['([A-Z])'],
    icon: CheckCircleIcon,
    colour: successGreen,
  },
  {
    text: $t('at-least-one-num'),
    display: false,
    regex: ['([0-9])'],
    icon: CheckCircleIcon,
    colour: successGreen,
  },
  {
    text: $t('min-eight-char'),
    display: false,
    regex: ['^(.){8,}$'],
    icon: CheckCircleIcon,
    colour: successGreen,
  },
  {
    text: $t('at-least-one-special-char'),
    display: false,
    regex: ['[^\\w\\s]+|_+'],
    icon: CheckCircleIcon,
    colour: successGreen,
  },
  {
    text: $t('please-remove-spaces'),
    display: false,
    regex: ['(\\s)'],
    icon: XCircleIcon,
    colour: 'error-500',
  },
  {
    text: $t('password-is-vulnerable'),
    display: false,
    regex: ['(?=.*password)', 'i'],
    icon: ExclamationIcon,
    colour: 'primary-gold-500',
  },
]);

function passwordRegex() {
  passwordSuggestions.forEach((ps) => {
    const regex = new RegExp(ps.regex[0], ps?.regex[1] ? ps.regex[1] : ''); // regex modifier
    const isValid = regex.test(String(modelValue.value));
    ps.display = isValid;
  });

  // display close suggestions chevron
  setTimeout(() => {
    showChevron.value =
      input?.value?.$params?.state?.meter?.strength === 'strong';
  }, 250);
}

//set focus to password component when toggling mask
function togglePassword() {
  // setTimeout(() => {
  //   const input = document.getElementsByClassName('p-password-input');
  //   input[0].focus();
  // }, 250);
}
function closePopUp() {}
</script>

<template>
  <div class="p-inputgroup w-full">
    <span class="p-float-label input--password">
      <Password
        ref="input"
        id="password"
        :feedback="props.feedback"
        inputClass="input--password-field w-full hidden-password-styling"
        :inputProps="{ autocomplete: 'off' }"
        :inputId="props.formKey"
        v-model="modelValue"
        toggleMask
        required
        @input="
          emit('update:modelValue', $event.target.value);
          showValue($event.target.value);
        "
        @blur="emit('blur')"
        @change="passwordRegex()"
        :toggleCallback="togglePassword()"
        panelClass="dark:bg-dark-800 bg-light-50 b-1 dark:b-dark-500 b-light-400 border-round-md p-0"
        promptLabel=" "
        mediumRegex="^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*[\d|\W])(?:.{8,20})$"
        strongRegex="^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)(?:.{8,20})$"
        :pt="{
          panel: ({ state }) => ({
            class:
              state?.meter?.strength === 'strong'
                ? 'hide-password-popup'
                : 'show-password-popup',
          }),
        }"
      >
        <template #showicon="{ toggleCallback }">
          <i
            class="pi pi-eye text-dark-600 dark:text-white mr-0.5"
            @click="toggleCallback"
          />
        </template>
        <template #hideicon="{ toggleCallback }">
          <i
            class="pi pi-eye-slash text-dark-600 dark:text-white mr-0.5"
            @click="toggleCallback"
          />
        </template>
        <template #header>
          <p
            class="mt-0 pb-2 mb-1 pl-2 pt-2 font-bold text-sm capitalize dark:text-white text-dark-700"
          >
            {{ $t('password-validity') }}
          </p>
          <i
            class="pi pi-angle-up text-sm close-popup"
            v-if="showChevron"
            @click="closePopUp()"
          />
        </template>
        <template #footer>
          <hr class="pl-0 w-full b-1 dark:b-dark-500 b-light-400" />
          <div class="pl-2">
            <p
              class="mt-2 mb-1 text-sm font-bold capitalize dark:text-white text-dark-700"
            >
              {{ $t('required') }}
            </p>
            <ul
              class="pl-0 ml-0 mt-0 list-none dark:text-white text-dark-700 mb-2"
              style="line-height: 1.5"
            >
              <div
                class="flex align-content-center flex-wrap"
                v-for="(ps, index) in passwordSuggestions"
              >
                <component
                  :is="ps.icon"
                  :key="index"
                  class="mr-2 w-4"
                  :class="`text-${ps.colour}`"
                  v-if="ps.display"
                  :tooltip="
                    toRaw(ps.icon) === ExclamationIcon
                      ? 'password-contains-password'
                      : ''
                  "
                />
                <CircleIcon
                  v-else-if="ps.icon === CheckCircleIcon"
                  class="mr-2 w-4"
                />
                <li
                  class="text-xs text-dark-700 dark:text-white"
                  v-if="ps.display || ps.icon === CheckCircleIcon"
                >
                  {{ ps.text }}
                </li>
                <!-- component and colour depending on suggestion/warning/error -->
              </div>
            </ul>
          </div>
        </template>
      </Password>
      <label
        class="input-label input--password-label"
        :class="props.labelClass"
        :for="props.label"
      >
        {{ props.label }}
      </label>
    </span>
  </div>
</template>
<style lang="scss">
.p-float-label .p-inputwrapper-filled ~ label.vueform-float-label {
  background-color: #fff;
  top: -2px;
  font-size: 12px;
  left: 10px;
}

label.vueform-float-label {
  font-size: 16px;
  top: 33%;
  padding-left: 4px;
}

.p-password-meter {
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 8px;
  background-color: #dae0ed;
  height: 6px;
}
.p-password-info {
  display: none;
}

div.p-password-meter {
  border-radius: 8px;
}

.p-password-strength {
  &.weak,
  &.medium,
  &.strong {
    border-radius: 8px;
  }
}

.dark {
  .p-float-label .p-inputwrapper-filled ~ label.vueform-float-label {
    background-color: #1d2129;
  }

  .p-password-meter {
    background-color: #121417;
  }
  div.p-password svg.p-icon {
    color: #fff;
  }
}
div.p-password svg.p-icon {
  color: #2b303b;
}

.hide-password-popup {
  animation-name: fadeOut;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    display: none;
  }
}

.show-password-popup {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    display: block;
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.hidden-password-styling {
  padding-left: 12px;
  letter-spacing: 2px !important;
}
</style>
<style scoped>
.close-popup {
  position: absolute;
  right: 8px;
  top: 8px;
  color: #fff;
  cursor: pointer;
}
</style>
